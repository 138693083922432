@font-face {
  font-family: "reforma--regular";
  src: url("../fonts/reforma/Reforma2018-Blanca.eot");
  src: url("../fonts/reforma/Reforma2018-Blanca.eot?#iefix") format("embedded-opentype"), url("../fonts/reforma/Reforma2018-Blanca.woff2") format("woff2"), url("../fonts/reforma/Reforma2018-Blanca.woff") format("woff"), url("../fonts/reforma/Reforma2018-Blanca.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "reforma--regular--italic";
  src: url("../fonts/reforma/Reforma2018-BlancaItalica.eot");
  src: url("../fonts/reforma/Reforma2018-BlancaItalica.eot?#iefix") format("embedded-opentype"), url("../fonts/reforma/Reforma2018-BlancaItalica.woff2") format("woff2"), url("../fonts/reforma/Reforma2018-BlancaItalica.woff") format("woff"), url("../fonts/reforma/Reforma2018-BlancaItalica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "reforma--medium";
  src: url("../fonts/reforma/Reforma2018-Gris.eot");
  src: url("../fonts/reforma/Reforma2018-Gris.eot?#iefix") format("embedded-opentype"), url("../fonts/reforma/Reforma2018-Gris.woff2") format("woff2"), url("../fonts/reforma/Reforma2018-Gris.woff") format("woff"), url("../fonts/reforma/Reforma2018-Gris.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:root {
  --ratio: 1.1;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.15;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.2;
  }
}
@-moz-keyframes reveal_from_right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes reveal_from_right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes reveal_from_right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes reveal_from_right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes reveal_from_left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes reveal_from_left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes reveal_from_left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes reveal_from_left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-o-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-moz-keyframes action_anchor_interaction--upwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes action_anchor_interaction--upwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes action_anchor_interaction--upwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes action_anchor_interaction--upwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes action_anchor_interaction--forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action_anchor_interaction--forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action_anchor_interaction--forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action_anchor_interaction--forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action_anchor_interaction--downwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes action_anchor_interaction--downwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes action_anchor_interaction--downwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes action_anchor_interaction--downwards {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes action_anchor_interaction--backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action_anchor_interaction--backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action_anchor_interaction--backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action_anchor_interaction--backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  outline-offset: 0;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
body {
/*
        the below three properties ensure that elements with
        fixed positioning will still display relative to the viewport.
    */
  will-change: unset;
  transform: none;
  filter: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
/*
        hide scrollbars when modal is visible.
    */
}
.modal_is_visible body {
  overflow: hidden;
  max-height: 100vh;
}
.main_content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page_content {
  flex: 1 0 auto;
  width: 100%;
}
.page_content__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1300px;
}
.page_content__inner > *:first-child {
  margin-top: 3.236rem;
}
.page_content__inner > *:last-child {
  margin-bottom: 3.236rem;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
img[height][width] {
  height: auto;
}
img[width] {
  width: auto;
}
img[src$=".svg"] {
  height: auto;
  width: 100%;
  max-width: none;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 10px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5vw + 7.6px);
  }
}
@media screen and (min-width: 1680px) {
  :root {
    font-size: 16px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}
body,
select,
input,
textarea {
  font-family: "reforma--regular", sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: -0.012em;
  color: #3f4455;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: "reforma--medium", sans-serif;
  font-weight: normal;
  color: #1f2432;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover {
  color: #4d64b3;
}
.content_area__content h1,
.content_area__content h2,
.content_area__content h3,
.content_area__content h4 {
  font-weight: normal;
}
h1,
h2 {
  letter-spacing: -0.028em;
}
h3,
h4 {
  letter-spacing: -0.018em;
}
h1 {
  max-width: 37ch;
  font-size: 1.464100000000001rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 1.749006249999999rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.0736rem;
    font-size: var(--fz-ratio-power--four);
  }
}
* + h1 {
  margin-top: 2.196150000000001rem;
  margin-top: var(--sp-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  * + h1 {
    margin-top: 2.623509374999999rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  * + h1 {
    margin-top: 3.1104rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
h2 {
  max-width: 44.4ch;
  font-size: 1.331rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.520875rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 1.728rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 1.996500000000001rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.281312499999999rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 2.592rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 51.8ch;
  font-size: 1.21rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 1.815rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 1.98375rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.16rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 59.2ch;
  font-size: 1.1rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.2rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.65rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.725rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.8rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: "reforma--regular", sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.012em;
  color: #3f4455;
/*
        add this class to paragraph content to constrain it to a readable line-length.
        avoid setting paragraph line-lengths globally so that they don't need to be overridden.
    */
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content {
  width: 74ch;
}
p > a,
p > a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
}
p > a:active,
p > a:visited:active,
p > a:focus,
p > a:visited:focus,
p > a:hover,
p > a:visited:hover {
  color: #4d64b3;
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
}
b,
strong {
  font-weight: normal;
  font-family: "reforma--medium", sans-serif;
  letter-spacing: -0.024em;
}
i,
em {
  font-style: normal;
  font-family: "reforma--regular--italic", sans-serif;
}
sup,
sub {
  font-size: 0.909090909090909rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup,
  sub {
    font-size: 0.869565217391304rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup,
  sub {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
sup {
  vertical-align: super;
}
sub {
  vertical-align: sub;
}
::selection {
  background-color: #3f4455;
  text-shadow: 0 0 0.1em #1f2432;
  color: #fff;
}
.reading_content {
  width: 100%;
}
.reading_content ul,
.reading_content ol,
.reading_content dl {
  display: block;
  width: 100%;
  max-width: 74ch;
  padding-left: 3ch;
}
.reading_content * + ul,
.reading_content * + ol,
.reading_content * + dl {
  margin-top: 1.077588rem;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content li {
  width: 74ch;
  max-width: 100%;
}
.reading_content li ul:first-child,
.reading_content li ol:first-child {
  margin-top: 0.809rem;
}
.reading_content * + li {
  margin-top: 0.809rem;
}
.action_anchor_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
* + .action_anchor_container {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .content_area__local_actions .action_anchor_container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.action_anchor {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 0 0 #fff;
  border-bottom: 1px solid;
  padding: 0 0.539333333333333rem 0.269666666666667rem;
}
.action_anchor_container .action_anchor {
  margin-bottom: 0.809rem;
}
.action_anchor_container .action_anchor:not(:last-child) {
  margin-right: 0.809rem;
}
.action_anchor_container .action_anchor,
.button_container .action_anchor {
  margin-top: 0.3rem;
}
.action_anchor--primary_action {
  box-shadow: inset 0 0 0 hsla(226,30%,95%,1);
  border-color: hsla(226,30%,95%,1);
  color: #334b99;
}
.action_anchor--primary_action:active,
.action_anchor--primary_action:focus,
.action_anchor--primary_action:hover {
  box-shadow: inset 0 -0.66em 0 hsla(226,30%,95%,1);
  border-color: #334b99;
}
.action_anchor--primary_action .triangle__inner,
.action_anchor--primary_action .triangle__path {
  fill: #7a89b8;
}
.action_anchor--secondary_action {
  box-shadow: inset 0 0 0 hsla(226,30%,95%,1);
  border-color: hsla(226,30%,95%,1);
  color: #334b99;
}
.action_anchor--secondary_action:active,
.action_anchor--secondary_action:focus,
.action_anchor--secondary_action:hover {
  box-shadow: inset 0 -0.66em 0 hsla(226,30%,95%,1);
  border-color: #7a89b8;
}
.action_anchor--secondary_action .triangle__inner {
  fill: #fff;
}
.action_anchor--secondary_action .triangle__path {
  fill: #7a89b8;
}
.action_anchor--reductive_action {
  box-shadow: inset 0 0 0 #fff0d9;
  border-color: #fff0d9;
  color: #ffa20d;
}
.action_anchor--reductive_action:active,
.action_anchor--reductive_action:focus,
.action_anchor--reductive_action:hover {
  box-shadow: inset 0 -0.66em 0 #fff0d9;
  border-color: #ffb640;
}
.action_anchor--reductive_action .triangle__inner {
  fill: #fff;
}
.action_anchor--reductive_action .triangle__path {
  fill: #ffa20d;
}
.action_anchor--risk_action {
  box-shadow: inset 0 0 0 #fadce2;
  border-color: #e85573;
  color: #e85573;
}
.action_anchor--risk_action:active,
.action_anchor--risk_action:focus,
.action_anchor--risk_action:hover {
  box-shadow: inset 0 -0.66em 0 #fadce2;
  border-color: #bd1a3b;
  color: #bd1a3b;
}
.action_anchor--risk_action .triangle__inner {
  fill: transparent;
}
.action_anchor--risk_action .triangle__path {
  fill: #bd1a3b;
}
.action_anchor--disabled {
  cursor: not-allowed;
  border-color: #cccace;
  color: #b2afb6;
}
.action_anchor--disabled:active,
.action_anchor--disabled:focus,
.action_anchor--disabled:hover {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 #fff;
  border-color: #cccace;
  color: #b2afb6;
}
.action_anchor--backwards_action:active .triangle,
.action_anchor--backwards_action:focus .triangle,
.action_anchor--backwards_action:hover .triangle {
  animation: action-anchor-interaction-backwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--forwards_action:active .triangle,
.action_anchor--forwards_action:focus .triangle,
.action_anchor--forwards_action:hover .triangle {
  animation: action-anchor-interaction-forwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--downwards_action:active .triangle,
.action_anchor--downwards_action:focus .triangle,
.action_anchor--downwards_action:hover .triangle {
  animation: action-anchor-interaction-downwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor__text {
  flex: 0 0 auto;
  word-break: keep-all;
}
.action_anchor .triangle {
  flex: 0 0 auto;
}
.action_anchor .triangle--right,
.action_anchor .triangle--left {
  height: 0.5em;
  width: 0.33em;
}
.action_anchor .triangle--right,
.action_anchor .triangle--down {
  margin-left: 0.539333333333333rem;
}
.action_anchor .triangle--left {
  margin-right: 0.539333333333333rem;
}
.action_anchor .triangle--down {
  height: 0.44em;
  width: 0.5852em;
}
.application_container {
  will-change: unset;
  transform: none;
  filter: none;
  overflow: auto;
  display: block;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
}
@media only screen and (min-width: 881px) {
  .application_container {
    overflow: hidden;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    max-height: 100vh;
  }
}
@media only screen and (max-width: 880px) {
  .application_container {
    padding-bottom: 4.818000000000001rem;
  }
}
.navigation_is_open .application_container {
  position: relative;
  z-index: auto;
}
.navigation_is_open .application_container::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(4.6,100%,98%,0.9);
}
.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -0.809rem;
  width: 100%;
}
* + .button_container {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .content_area__local_actions .button_container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.button {
  will-change: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-property: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  padding: 0.134833333333333rem 0.539333333333333rem 0.4045rem;
  word-break: keep-all;
}
.button_container .button {
  margin-bottom: 0.809rem;
}
.button_container .button:not(:last-child) {
  margin-right: 0.809rem;
}
.button--primary_action {
  box-shadow: inset 0 -2px 0 #334b99;
  border-color: #334b99;
  border-top-color: #4d64b3;
  background-color: #4d64b3;
  text-shadow: 0 0 2px #334b99;
  color: #fff;
}
.button--primary_action:active,
.button--primary_action:focus,
.button--primary_action:hover {
  box-shadow: inset 0 2px 2px #334b99;
  border-top-color: #4d64b3;
  background-color: #4d64b3;
}
.button--secondary_action {
  box-shadow: inset 0 -2px 0 #7a89b8;
  border-color: #4d64b3;
  background-color: #fff;
  color: #334b99;
}
.button--secondary_action:active,
.button--secondary_action:focus,
.button--secondary_action:hover {
  box-shadow: inset 0 1px 4px #7a89b8;
  border-color: #4d64b3;
}
.button--reductive_action {
  box-shadow: inset 0 -2px 0 #fff0d9;
  color: #ffa20d;
}
.button--reductive_action border-color $cl--warn {
  background-color: #fff;
}
.button--reductive_action:active,
.button--reductive_action:focus,
.button--reductive_action:hover {
  box-shadow: inset 0 1px 2px hsla(37,100%,83%,1);
  border-color: #ffa20d;
  border-top-color: #ffb640;
}
.button--risk_action {
  box-shadow: inset 0 -2px 0 #bd1a3b;
  border-color: #bd1a3b;
  border-top-color: #e85573;
  background-color: #e85573;
  color: #fff;
}
.button--risk_action:active,
.button--risk_action:focus,
.button--risk_action:hover {
  box-shadow: inset 0 1px 2px #bd1a3b;
  border-top-color: #bd1a3b;
}
.button--disabled {
  box-shadow: inset 0 -2px 0 #cccace;
  border-color: #b2afb6;
  background-color: #f7f6f8;
  color: #b2afb6;
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  cursor: not-allowed;
}
.content_area {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1300px;
}
@media only screen and (min-width: 881px) {
  .content_area {
    flex-direction: row-reverse;
  }
}
* + .content_area {
  margin-top: 1.618rem;
}
.content_area__local_actions,
.content_area__content {
  display: block;
}
.content_area__local_actions {
  flex: 0 0 auto;
}
@media only screen and (min-width: 881px) {
  .content_area__local_actions {
    margin-right: -0.809rem;
    margin-left: 1.618rem;
    border-radius: 10px 0 0 10px;
    border-left: 1px solid #fcceca;
    padding: 1.618rem 0 1.618rem 1.618rem;
  }
}
.content_area__content {
  flex: 1 1 auto;
}
@media only screen and (max-width: 880px) {
  * + .content_area__content {
    margin-top: 1.077588rem;
  }
}
@media only screen and (min-width: 881px) {
  .content_area__content:first-child {
    margin-right: auto;
  }
}
.content_area__alerts {
  display: inline-block;
  border-bottom: 1px solid #fcceca;
  padding: 0 0 1.618rem;
}
.data__summary {
  display: block;
  line-height: 1.5;
  font-family: "reforma--medium", sans-serif;
  font-weight: normal;
  letter-spacing: $ls--heading-one-two;
  color: #3f4455;
}
* + .data__summary {
  margin-top: 0.269666666666667rem;
}
.data__summary__arrow {
  color: #fff3f2;
}
.data__list {
  display: block;
  width: 100%;
  max-width: 100%;
}
* + .data__list {
  margin-top: 1.077588rem;
}
.data__item {
  width: 100%;
  max-width: 100%;
  border: 1px solid #fcceca;
  border-radius: 4px;
}
* + .data__item {
  margin-top: 1.618rem;
}
.data__item__heading {
  padding: 0.4045rem 0.809rem;
}
.data__item__data_content {
  background-color: #1f2432;
  padding: 0.809rem;
  text-shadow: 0 0 0.1em #1f2432;
  color: #fff;
}
.data__item__data_definition__list {
  display: block;
  width: 100%;
}
.data__item__data_definition__item {
  display: flex;
  flex-direction: row;
}
* + .data__item__data_definition__item {
  border-top: 1px solid #fcceca;
}
.data__item__data_definition__term,
.data__item__data_definition__description {
  padding: 0.4045rem 0.809rem;
}
.data__item__data_definition__term {
  flex: 0 0 12rem;
}
.data__item__data_definition__description {
  flex: 1 1 0;
  border-left: 1px solid #fcceca;
}
.data__item__actions {
  border-top: 1px solid #fcceca;
  padding: 0.809rem;
}
* + .form {
  margin-top: 1.618rem;
}
.form__label {
  display: block;
  width: 100%;
  max-width: 74ch;
  font-family: "reforma--medium", sans-serif;
  color: #3f4455;
}
* + .form__label {
  margin-top: 1.618rem;
}
.form__label::after {
  content: ":";
}
* + .form__field {
  margin-top: 0.4045rem;
}
.form__field input,
.form__field textarea {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  width: 74ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #fff3f2;
  border-radius: 4px;
  border: 1px solid #fcceca;
  border-left-width: 2px;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form__field input::-webkit-input-placeholder,
.form__field textarea::-webkit-input-placeholder {
  color: #6a6e7c;
  opacity: 1;
}
.form__field input:-moz-placeholder,
.form__field textarea:-moz-placeholder {
  color: #6a6e7c;
  opacity: 1;
}
.form__field input::-moz-placeholder,
.form__field textarea::-moz-placeholder {
  color: #6a6e7c;
  opacity: 1;
}
.form__field input:-ms-input-placeholder,
.form__field textarea:-ms-input-placeholder {
  color: #6a6e7c;
  opacity: 1;
}
.form__field input:active,
.form__field textarea:active,
.form__field input:focus,
.form__field textarea:focus,
.form__field input:hover,
.form__field textarea:hover {
  outline: none;
  box-shadow: inset 0 -1px 0 #fff3f2;
  border-color: #fcceca;
}
.form__select_container {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 74ch;
}
* + .form__select_container {
  margin-top: 0.539333333333333rem;
}
.form__select_container::before {
  content: "";
  transform: translateY(-50%);
  pointer-events: none;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0.809rem;
  height: 0.509rem;
  width: 0.809rem;
  background-image: url("../images/select_triangle.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.form__select_container select {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: inset 0 -$bw 0 #fff3f2;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #fcceca;
  border-left-width: 2px;
  background-color: #fff;
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2135rem;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
  padding-bottom: calc(0.539333333333333rem + 2px);
  color: #3f4455;
}
.form__select_container select:active,
.form__select_container select:focus,
.form__select_container select:hover {
  outline: none;
  box-shadow: inset 0 -1px 0 #fff3f2;
  border-color: #fcceca;
}
.form__checkbox {
  position: relative;
  z-index: auto;
  display: block;
}
.form__checkbox + .form__checkbox {
  margin-top: 0.539333333333333rem;
}
.form__checkbox__input {
  cursor: pointer;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
}
.form__checkbox__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: 1.752833333333333rem;
}
.form__checkbox__label::before,
.form__checkbox__label::after {
  will-change: opacity, border-color, transform, zoom;
  transition-property: opacity, border-color, transform, zoom;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  content: "";
  position: absolute;
  display: inline-block;
}
.form__checkbox__label::before {
  top: 0.24rem;
  left: 0;
  height: 1.2135rem;
  width: 1.2135rem;
  border-radius: 4px;
  border: 1px solid #fcceca;
  background-color: #fff;
}
.form__checkbox__input:checked + .form__checkbox__label::before {
  border-color: #fcceca;
}
.form__checkbox__label::after {
  z-index: 110;
  transform: rotate(-10deg) scale(0.4);
  top: 0.52rem;
  left: 0.2rem;
  height: 0.4045rem;
  width: 0.809rem;
  border-bottom: 2px solid #fcceca;
  border-left: 2px solid #fcceca;
}
.form__checkbox__input + .form__checkbox__label::after {
  opacity: 0;
}
.form__checkbox__input:checked + .form__checkbox__label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.global_header {
  z-index: 20;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid #fcceca;
  background-color: #fff;
  padding: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .global_header {
    position: static;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    margin-top: 1.618rem;
    margin-bottom: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    border-radius: 0 10px 10px 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    border-top: none;
    border-right: 1px solid #fcceca;
  }
}
.global_header__navigation_button_and_app_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 880px) {
  .global_header__navigation_button_and_app_name {
    width: 100%;
    max-width: 100%;
  }
}
.global_header__navigation_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3236rem;
  margin-left: auto;
  background-color: #fff;
}
@media only screen and (min-width: 881px) {
  .global_header__navigation_button {
    display: none;
  }
}
.global_header__navigation_button__vector {
  will-change: fill;
  transition-property: fill;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  height: 1.5rem;
  width: 2.0225rem;
  fill: #334b99;
}
.global_header__navigation_button:hover .global_header__navigation_button__vector {
  fill: #4d64b3;
}
.navigation_is_open .global_header__navigation_button__vector--open {
  display: none;
}
.global_header__navigation_button__vector--close {
  display: none;
  transform: scale(80%);
}
.navigation_is_open .global_header__navigation_button__vector--close {
  display: block;
}
.global_header__app_name {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: block;
  font-size: 1.21rem;
  font-size: var(--fz-ratio-power--two);
  font-weight: normal;
  line-height: 1;
  letter-spacing: -0.028em;
  color: #334b99;
}
@media only screen and (max-width: 880px) {
  .global_header__app_name {
    margin-right: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .global_header__app_name {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .global_header__app_name {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.global_header__app_name:active,
.global_header__app_name:focus,
.global_header__app_name:hover {
  color: #4d64b3;
}
@media only screen and (max-width: 880px) {
  .global_navigation[hidden] {
    display: none;
  }
}
.global_navigation__list {
  flex: 0 0 auto;
  display: block;
  margin-top: 1.618rem;
  margin-right: 0.4045rem;
  border-top: 1px solid #fcceca;
  padding-top: 1.618rem;
}
.global_navigation__item {
  width: 100%;
  line-height: 1.5;
}
* + .global_navigation__item {
  margin-top: 1.077588rem;
}
.global_navigation__anchor {
  display: inline-block;
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
}
.global_navigation__anchor:active,
.global_navigation__anchor:focus,
.global_navigation__anchor:hover {
  color: #4d64b3;
}
.list_view__list {
  width: 100%;
  max-width: 100%;
}
* + .list_view__list {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .list_view__list {
    padding-left: 0.539333333333333rem;
  }
}
.list_view__item {
  will-change: box-shadow, transform;
  transition-property: box-shadow, transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  position: relative;
  z-index: auto;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: auto;
  width: 100%;
  max-width: 100%;
  padding: 0.539333333333333rem 0 0.809rem;
}
@media only screen and (min-width: 481px) {
  .list_view__item {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .list_view__item {
    padding: 0.539333333333333rem 1.618rem 0.809rem;
  }
}
.list_view__item[open] {
  flex-wrap: wrap;
}
* + .list_view__item {
  border-top: 1px solid #fcceca;
}
@media only screen and (min-width: 881px) {
  .list_view__item::before,
  .list_view__item::after {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    z-index: auto;
    left: -0.54rem;
    display: block;
    height: 0.24rem;
    width: 0.24rem;
    border-radius: 50%;
    background-color: #fcceca;
  }
}
.list_view__item::before {
  top: calc(50% - 0.2rem);
}
.list_view__item::after {
  top: calc(50% + 0.2rem);
}
.list_view__header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}
@media only screen and (min-width: 481px) {
  .list_view__header {
    flex-direction: row;
  }
}
.list_view__heading_and_description,
.list_view__heading_and_priority_information {
  flex: 0 0 100%;
}
@media only screen and (min-width: 481px) {
  .list_view__heading_and_description,
  .list_view__heading_and_priority_information {
    flex: 0 1 auto;
  }
}
@media only screen and (max-width: 480px) {
  .list_view__heading_and_description,
  .list_view__heading_and_priority_information {
    width: 100%;
  }
}
@media only screen and (min-width: 881px) {
  .list_view__heading_and_description::before,
  .list_view__heading_and_priority_information::before {
    content: "";
    position: absolute;
    z-index: auto;
    top: 10%;
    left: -2px;
    width: 2px;
    background-color: #fcceca;
    height: 80%;
  }
}
.list_view__item[open] .list_view__heading_and_description::before,
.list_view__item[open] .list_view__heading_and_priority_information::before {
  top: 0;
  height: 100%;
}
.list_view__heading {
  font-size: 1.1rem;
  font-size: var(--fz-ratio-power--one);
}
* + .list_view__heading {
  margin-top: 0;
}
@media only screen and (min-width: 681px) {
  .list_view__heading {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__heading {
    font-size: 1.2rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.list_view__description {
  display: block;
  width: 100%;
  max-width: 74ch;
}
* + .list_view__description {
  margin-top: 0.3236rem;
}
.list_view__priority_information__list {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0.4045rem;
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__list {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__list {
    flex-direction: column;
    align-items: stretch;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__list {
    margin-top: 0;
    margin-bottom: 0.1em;
    margin-left: 0.809rem;
  }
}
@media only screen and (max-width: 480px) {
  .list_view__priority_information__list {
    width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__list {
    border-left: 1px solid #fff3f2;
    padding-left: 0.809rem;
  }
}
.list_view__priority_information__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 0.909090909090909rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__item {
    justify-content: space-between;
  }
  * + .list_view__priority_information__item {
    margin-top: 0.20225rem;
  }
}
@media only screen and (min-width: 681px) {
  .list_view__priority_information__item {
    font-size: 0.869565217391304rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__priority_information__item {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.list_view__priority_information__item:last-child .list_view__priority_information__value {
  box-shadow: inset 0 -0.2em 0 #fcceca;
}
.list_view__priority_information__label,
.list_view__priority_information__value {
  flex: 0 0 auto;
  display: inline-block;
}
.list_view__priority_information__label::after {
  content: ":";
}
.list_view__priority_information__value {
  margin-left: 1ch;
}
.list_view__expand_button {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
}
* + .list_view__expand_button {
  margin-left: 0.539333333333333rem;
}
@media only screen and (min-width: 481px) {
  * + .list_view__expand_button {
    margin-left: 1.077588rem;
  }
}
.list_view__expand_button__vector {
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4045rem;
}
.list_view__item[open] .list_view__expand_button__vector {
  transform: rotate(180deg);
}
.list_view__expand_button__vector svg {
  flex: 0 0 auto;
  margin-top: 0.35rem;
  height: 0.3236rem;
  width: 0.5rem;
  fill: #4d64b3;
}
.list_view__expand_button__label {
  flex: 0 0 auto;
  display: inline-block;
  font-size: 0.909090909090909rem;
  font-size: var(--fz-ratio-power--minus-one);
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
}
* + .list_view__expand_button__label {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 681px) {
  .list_view__expand_button__label {
    font-size: 0.869565217391304rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__expand_button__label {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.list_view__expand_button__label:active,
.list_view__expand_button__label:focus,
.list_view__expand_button__label:hover {
  color: #4d64b3;
}
.list_view__content {
  display: none;
  flex: 0 0 100%;
  width: 100%;
}
* + .list_view__content {
  margin-top: 1.077588rem;
}
.list_view__item[open] .list_view__content {
  display: block;
}
.list_view__meta_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border: 1px solid #fff3f2;
  border-radius: 4px;
  padding: 0 0.809rem;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_list {
    display: block;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_list {
    padding: 0;
  }
}
.list_view__meta_item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.809rem 0;
  font-size: 0.909090909090909rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .list_view__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_item {
    padding: 0;
  }
}
@media only screen and (min-width: 681px) {
  .list_view__meta_item {
    font-size: 0.869565217391304rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__meta_item {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .list_view__meta_item {
  border-top: 1px solid #fff3f2;
}
.list_view__meta_term,
.list_view__meta_description {
  padding: 0;
  overflow-wrap: anywhere;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term,
  .list_view__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.list_view__meta_term a,
.list_view__meta_description a,
.list_view__meta_term a:visited,
.list_view__meta_description a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
  font-weight: normal;
}
.list_view__meta_term a:active,
.list_view__meta_description a:active,
.list_view__meta_term a:visited:active,
.list_view__meta_description a:visited:active,
.list_view__meta_term a:focus,
.list_view__meta_description a:focus,
.list_view__meta_term a:visited:focus,
.list_view__meta_description a:visited:focus,
.list_view__meta_term a:hover,
.list_view__meta_description a:hover,
.list_view__meta_term a:visited:hover,
.list_view__meta_description a:visited:hover {
  color: #4d64b3;
}
.list_view__meta_term {
  flex: 0 0 auto;
  font-family: "reforma--medium", sans-serif;
  color: #3f4455;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 480px) {
  .list_view__meta_term {
    padding-bottom: 0;
  }
}
.list_view__meta_term::after {
  content: ":";
}
.list_view__meta_description {
  flex: 0 0 auto;
  color: #6a6e7c;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 480px) {
  * + .list_view__meta_description {
    margin-top: 0.4045rem;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_description {
    border-left: 1px solid #fff3f2;
  }
}
.list_view__count {
  margin-left: 0.25ch;
  font-size: 0.826446280991735rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .list_view__count {
    font-size: 0.756143667296787rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__count {
    font-size: 0.694444444444445rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.local_header {
  flex: 0 0 auto;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1300px;
}
.local_header::after {
  content: "";
  display: block;
  margin-top: 0.809rem;
  background-image: repeating-linear-gradient(to bottom, #fcceca, #fcceca 1px, transparent 1px, transparent 5px);
  background-repeat: repeat-x;
  background-position: top;
  background-size: 1px calc(5px * 5 + 1px);
  height: calc(5px * 5);
}
.local_header__page_summary {
  display: block;
  line-height: 1.5;
  font-family: "reforma--medium", sans-serif;
  font-weight: normal;
  letter-spacing: $ls--heading_one_two;
  color: #3f4455;
}
* + .local_header__page_summary {
  margin-top: 0.4045rem;
}
.local_header__page_summary__arrow {
  color: #fcceca;
}
.meta_list__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border: 1px solid #fcceca;
  border-radius: 4px;
  padding: 0 0.809rem;
}
@media only screen and (min-width: 481px) {
  .meta_list__list {
    display: block;
  }
}
* + .meta_list__list {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 481px) {
  .meta_list__list {
    padding: 0;
  }
}
.meta_list__item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.809rem 0;
  font-size: 0.909090909090909rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .meta_list__item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__item {
    padding: 0;
  }
}
@media only screen and (min-width: 681px) {
  .meta_list__item {
    font-size: 0.869565217391304rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .meta_list__item {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .meta_list__item {
  border-top: 1px solid #fcceca;
}
.meta_list__term,
.meta_list__description {
  padding: 0;
  overflow-wrap: anywhere;
}
@media only screen and (min-width: 481px) {
  .meta_list__term,
  .meta_list__description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.meta_list__term a,
.meta_list__description a,
.meta_list__term a:visited,
.meta_list__description a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #334b99;
  font-weight: normal;
}
.meta_list__term a:active,
.meta_list__description a:active,
.meta_list__term a:visited:active,
.meta_list__description a:visited:active,
.meta_list__term a:focus,
.meta_list__description a:focus,
.meta_list__term a:visited:focus,
.meta_list__description a:visited:focus,
.meta_list__term a:hover,
.meta_list__description a:hover,
.meta_list__term a:visited:hover,
.meta_list__description a:visited:hover {
  color: #4d64b3;
}
.meta_list__term {
  flex: 0 0 auto;
  font-family: "reforma--medium", sans-serif;
  color: #3f4455;
}
@media only screen and (min-width: 481px) {
  .meta_list__term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 480px) {
  .meta_list__term {
    padding-bottom: 0;
  }
}
.meta_list__term::after {
  content: ":";
}
.meta_list__description {
  flex: 0 0 auto;
  color: #6a6e7c;
}
@media only screen and (min-width: 481px) {
  .meta_list__description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 480px) {
  * + .meta_list__description {
    margin-top: 0.4045rem;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__description {
    border-left: 1px solid #fcceca;
  }
}
.meta_list__count {
  margin-left: 0.25ch;
  font-size: 0.826446280991735rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .meta_list__count {
    font-size: 0.756143667296787rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .meta_list__count {
    font-size: 0.694444444444445rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.view_content {
  flex: 1 1 0;
  overflow-y: auto;
}
@media only screen and (min-width: 881px) {
  .view_content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
.view_content > *:first-child {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 881px) {
  .view_content > *:first-child {
    margin-top: 1.618rem;
  }
}
.view_content > *:last-child {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 881px) {
  .view_content > *:last-child {
    margin-bottom: 1.618rem;
  }
}
